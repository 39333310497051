<template>
    <item-card>
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                Pamatdati
            </h3>
        </template>
        <template v-slot:buttons>

            <Button icon="email-alert" tooltip="Nosūtīt rēķinu e-pastā"
                    @click="showForm('SendOrderInvoiceByEmailForm')"/>

            <Button icon="pencil" @click="showForm('EditOrderDetails')"/>


            <Button icon="briefcase-download" tooltip="Lejuplādēt Proforma rēķinu" :tooltipRight="true"
                    @click="downloadProformaPdf"/>

            <template v-if="item.allow_download_excel">
                <Button icon="download" tooltip="Lejuplādēt Excel ar kodiem" :tooltipRight="true"
                        @click="downloadExcel"/>
            </template>


            <Button icon="truck" tooltip="Rediģēt piegādes datus" :tooltipRight="true"
                    @click="showForm('EditOrderShippingDetails')"/>

            <Button v-if="item.allow_delete"
                    icon="delete" tooltip="Dzēst pasūtījumu" :tooltipRight="true" @click="deleteOrder"/>

        </template>

        <template v-slot:content>

            <template v-if="displayForm('addOrderCustomerForm')">
                <AddCustomerForm :order="item"/>
            </template>

            <template v-if="displayForm('SendOrderInvoiceByEmailForm')">
                <SendInvoiceByEmail :item="item"/>
            </template>

            <template v-if="!displayForm('addOrderCustomerForm') && !displayForm('SendOrderInvoiceByEmailForm')">
                <template v-if="item">
                    <template v-if="!displayForm('EditOrderDetails') && !displayForm('MergeOrders')">
                        <ShowOrderDetails :item="item"/>

                        <template v-if="item.invoice">
                            <ShowOrderInvoice :item="item"/>
                        </template>

                        <template v-if="item.credit_notes && !displayForm('editOrderInvoice') && !displayForm('EditOrderDetails')">
                            <div class="font-semibold mt-4 w-full">Kredītrēķini</div>
                            <div v-for="creditNote in item.credit_notes" :key="creditNote.id" class="w-full">
                                <ShowOrderCreditNote :item="creditNote"/>
                            </div>
                        </template>
                    </template>

                    <template v-if="displayForm('EditOrderDetails')">
                        <EditOrderDetails :order="item"/>
                    </template>

                    <template v-if="displayForm('EditOrderShippingDetails')">
                        <EditOrderShippingDetails :order="item"/>
                    </template>

                </template>
            </template>

        </template>
    </item-card>
</template>

<script>
import {mapGetters} from 'vuex'

import {defineAsyncComponent} from 'vue'

const ItemCard = defineAsyncComponent(() =>
    import('@/components/Components/ItemCard'))
const ShowOrderDetails = defineAsyncComponent(() =>
    import('@/components/Orders/OrderDetails/ShowOrderDetails'))
const EditOrderDetails = defineAsyncComponent(() =>
    import('@/components/Orders/OrderDetails/EditOrderDetails'))
const EditOrderShippingDetails = defineAsyncComponent(() =>
    import('@/components/Orders/OrderDetails/EditOrderShippingDetails'))
const AddCustomerForm = defineAsyncComponent(() =>
    import('@/components/Orders/OrderDetails/AddCustomerForm'))
const ShowOrderInvoice = defineAsyncComponent(() =>
    import('@/components/Orders/OrderInvoice/ShowOrderInvoice'))
const SendInvoiceByEmail = defineAsyncComponent(() =>
    import('@/components/Orders/OrderInvoice/SendInvoiceByEmail'))
const ShowOrderCreditNote = defineAsyncComponent(() =>
    import('@/components/Orders/CreditNotes/ShowOrderCreditNote'))


export default {
    name: "OrderDetails",
    components: {
        ItemCard,
        ShowOrderDetails,
        EditOrderDetails,
        EditOrderShippingDetails,
        AddCustomerForm,
        ShowOrderInvoice,
        SendInvoiceByEmail,
        ShowOrderCreditNote
    },
    props: ['item'],
    data: () => ({
        showDetails: false,
    }),
    computed: {
        ...mapGetters({
            formsForDisplay: 'formsForDisplay',
        }),
    },
    methods: {
        showForm(formName) {
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName) {
            return this.formsForDisplay.includes(formName)
        },
        deleteOrder() {
            if (confirm('Vai esi drošs?')) {
                this.$store.dispatch('deleteOrder', this.item.id)
            }
        },
        downloadProformaPdf() {
            this.$store.dispatch('getOrderProformaInvoicePdf', this.item)
        },

        downloadExcel() {
            this.$store.dispatch('getOrderItemExcel', this.item)
        }
    }
}
</script>
